@mixin heading-numbers {
  counter-reset: h2counter;

  h2:before {
    content: counter(h2counter) ".\0000a0";
  }
  h2 {
    counter-increment: h2counter;
    counter-reset: h3counter;
  }
  h3:before {
    content: counter(h2counter) "." counter(h3counter) ".\0000a0";
  }
  h3 {
    counter-increment: h3counter;
    counter-reset: h4counter;
  }
  h4:before {
    content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ".\0000a0";
  }
  h4 {
    counter-increment: h4counter;
    counter-reset: h5counter;
  }
  h5:before {
    content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "." counter(h5counter) ".\0000a0";
  }
  h5 {
    counter-increment: h5counter;
    counter-reset: h6counter;
  }
  h6:before {
    content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "." counter(h5counter) "." counter(h6counter) ".\0000a0";
  }
  h6 {
    counter-increment: h6counter;
  }

  h2, h3, h4, h5, h6 {
    &:before {
      @content;
    }
  }
}
