@import '_variables';

// Normalize everything
// IMPORTANT: Do NOT add ".css" to the end or else the file won't be inlined (and thus trigger a CSP violation - at least during dev)!!!
@import '../node_modules/modern-normalize/modern-normalize';


// Define fonts
@import 'fonts/_main-font';
@import 'fonts/_headings-font';
@import 'fonts/_code-font';

// Define icons
@import 'fonts/_fontawesome';

// Base styles
@import '_base';

// Import mixins
@import 'mixins/_heading_numbers';
@import 'mixins/_right_lined';

// Define all components
@import 'components/_404';
@import 'components/_app';
@import 'components/_backdrop';
@import 'components/_info-box';
@import 'components/_homepage';
@import 'components/_page';
@import 'components/_pages_list';
@import 'components/_pagination';
@import 'components/_page-meta';
@import 'components/_search';
@import 'components/_term';
@import 'components/_terms_list';
@import 'components/_toc';
@import 'components/_tooltips';
