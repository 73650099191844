.pagination {
  display: block;
  list-style: none;
  padding: 0;
  font-size: 0.8em;
  text-align: center;
  margin: 3em 0;
}

.page-item {
  display: inline-block;
  .page-link {
    display: block;
    padding: 0.285em 0.8em;
  }

  &.active {
    .page-link {
      color: #fff;
      border-radius: 2em;
      background: $header-footer-background;
    }
  }
}
