.error-404-title {
  font-size: 7em;
  margin-bottom: 0;
}

.error-404-subtitle {
  font-size: 1.5em;
  max-width: 600px;
  margin-top: 0;
}
